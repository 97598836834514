<template>
  <div>
    <sub-head v-bind:subTitle="content['NEWS']"></sub-head>
    <div class="page_padding">
      <div class="content">
        <div v-for="(item, index) in newsContents" :key="index" class="news">
          <a v-bind:href="item.link" target="_blank">
            <div class="news_table">
              <div class="cell-1">
                <span class="fontS word_break text333">{{ item.kind }}</span>
              </div>
              <div class="cell-2">
                <span class="fontS word_break text333">{{ item.date }}</span>
              </div>
              <div class="cell-3 text-left flex-set paddingM">
                <div style="float:left">
                  <span class="fontS word_break text333">{{ item.title }}</span>
                </div>
              </div>
              <div>
                <div class="imgWrap" style="float:right">
                  <img src="../../assets/source/images/ic_newsmore.png" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonContents from "../../assets/source/json/sub_top.json";
import subHead from "../../components/sub_head.vue";
export default {
  components: {subHead},
  data() {
    return {
      content: jsonContents,
      newsContents: [
        {
          kind: "기사",
          date: "2021.12.09",
          link: "http://www.segyebiz.com/newsView/20211209511008?OutUrl=naver",
          title: "CS렌탈, 시니어 스마트 여가생활 ‘해피테이블’ 렌탈 서비스 제공",
        },
        {
          kind: "기사",
          date: "2021.11.15",
          link: "http://www.geconomy.co.kr/news/article.html?no=186763",
          title: "대구시, '2021 대구 액티브시니어 박람회' 성황리 막내려",
        },
        {
          kind: "기사",
          date: "2021.11.11",
          link: "http://mbnmoney.mbn.co.kr/news/view?news_no=MM1004476881",
          title:
            "'시니어는 청춘이다' 2021대구액티브시니어박람회, '제론테크놀로지' 화두로 제시",
        },
        {
          kind: "기사",
          date: "2021.10.21",
          link:
            "http://www.shinailbo.co.kr/news/articleView.html?idxno=1473522",
          title: "사천시치매안심센터, 인지기능 향상 '해피테이블’ 운영",
        },
        {
          kind: "기사",
          date: "2021.09.07",
          link: "https://www.breaknews.com/832479",
          title: "중랑노인종합복지관, 치매예방을 위한 “해피테이블” 운영",
        },
        {
          kind: "기사",
          date: "2021.06.09",
          link: "https://www.sentv.co.kr/news/view/595571",
          title:
            "㈜사랑과선행, 홍보모델 가수 박군과 함께 고령자 헬스케어 사업 출범식 진행",
        },
        {
          kind: "기사",
          date: "2021.06.09",
          link: "https://news.mt.co.kr/mtview.php?no=2021060917153829867",
          title:
            "'대세' 박군, ㈜사랑과선행 고령 헬스케어 홍보모델 선정 \"어르신~건강 챙기세요\"",
        },
        {
          kind: "기사",
          date: "2021.06.08",
          link: "http://www.mkhealth.co.kr/news/articleView.html?idxno=53453",
          title:
            "[건강미박람회] KJ이노베이션, ‘해피테이블’ 등 시니어 헬스케어 기기 소개",
        },
        {
          kind: "기사",
          date: "2020.12.24",
          link: "https://platum.kr/archives/155200",
          title:
            "시니어 인지능력 진단 솔루션 개발사 ‘스프링소프트’, 소풍벤처스에서 첫 투자 유치",
        },

        {
          kind: "영상",
          date: "2020.11.12",
          link: "../../Assets/source/Video/다큐On.mp4",
          title: "KBS 다큐ON 주거 대변혁-공공임대주택 中 해피테이블 출연 영상",
        },
        {
          kind: "기사",
          date: "2020.07.10",
          link:
            "http://www.mkhealth.co.kr/NEWS/view.php?mcode=&NCode=MKH200710011",
          title:
            "[건강미박람회] 한국케어테크협회, 초고령 사회 미래 돌봄 신기술 선보여",
        },
        {
          kind: "기사",
          date: "2019.11.11",
          link:
            "http://web.kangnam.ac.kr/menu/board/info/d1c55253cbac464055ea9d7873f60a48.do?encMenuSeq=f27334797be7f56644db09015634bf5b&encMenuBoardSeq=90a381329e7ff4622dbf65e20e82886a",
          title:
            "강남대학교 미래복지융복합연구소와 KT텔레캅(주), ㈜맨엔텔, ㈜스프링소프트 3개 업체와 상호협력 MOU 체결",
        },
        {
          kind: "기사",
          date: "2019.09.23",
          link: "http://kr.aving.net/news/view.php?articleId=1538536",
          title:
            "[2019 성남국제의료관광컨벤션 영상] 스프링소프트, 치매예방 돕는 '해피테이블' 선봬",
        },
        {
          kind: "영상",
          date: "2019.09.19",
          link: "https://www.youtube.com/watch?v=PBLaatHrD7c",
          title:
            "[2019 성남 국제 의료 관광 컨벤션] 스프링소프트, 추억의 칠판으로 놀면서 예방하는 '해피테이블' 선보여",
        },
        {
          kind: "기사",
          date: "2019.09.19",
          link: "http://www.asiaherald.co.kr/news/24341",
          title:
            "[경기도 스타트업 이야기 #1] 경기도가 육성한 헬스케어 스타트업 3사",
        },
      ],
    };
  },
};
</script>
<style scoped>
div.news_table {
  display: table;
  width: 100%;
}

div.news_table div {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
}
.cell-1 {
  width: 10%;
  padding-right: 10px;
}
.cell-3 {
  width: 70%;
}
.imgWrap {
  width: 25px;
  height: 25px;
  padding: 0.2rem;
  border-radius: 50%;
}
.imgWrap img {
  width: 100%;
  height: 100%;
  margin-top: auto;
}
a:hover .imgWrap {
  background-color: black;
}
a:hover .cell-3 span {
  color: #dc375d;
}
a {
  text-decoration: none;
}
.imgWrap {
  background-color: gray;
}
.news {
  padding: 1rem 0.5rem;
  border-bottom: 1px solid lightgray;
}
.content .news:first-child {
  border-top: 2px solid gray;
}
.content {
  padding: 3rem 0;
}
@media (max-width: 767px) {
  .page_padding {
    padding: 0 5% !important;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .paddingM {
    padding: 0 1rem;
  }
  .imgWrap {
    width: 20px;
    height: 20px;
    padding: 0.2rem;
    border-radius: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .paddingM {
    padding: 0 0.8rem;
  }
  .imgWrap {
    width: 15px;
    height: 15px;
    padding: 0.2rem;
    border-radius: 50%;
  }
}
@media (max-width: 767px) {
  .paddingM {
    padding: 0 1rem;
  }
  .fontS {
    font-size: 12px;
  }
}
</style>
